<template>
  <!--
    页面名称：我的题库-练习
    开发人：黄wj
  -->
  <div class="practice">
    <div class="practice-bg">
      <div class="practice-bg-model">
        <div class>
          <div class="practice-name">{{ name }}</div>
          <div class="practice-sum">共{{ sum }}题</div>
        </div>
      </div>
    </div>
    <div class="practice-content">
      <div class="practice-content-model">
        <!-- 菜单 -->
        <div class="practice-content-model-menu">
          <div
            class="practice-content-model-menu-n"
            @click="toData('章节练习')"
          >
            <div
              :class="
                trigger == '章节练习'
                  ? 'practice-content-model-menu-n-name1'
                  : 'practice-content-model-menu-n-name2'
              "
            >
              章节练习
            </div>
            <div
              :class="
                trigger == '章节练习'
                  ? 'practice-content-model-menu-n-line1'
                  : 'practice-content-model-menu-n-line2'
              "
            ></div>
          </div>

          <div
            class="practice-content-model-menu-n"
            @click="toData('模拟试题')"
          >
            <div
              :class="
                trigger == '模拟试题'
                  ? 'practice-content-model-menu-n-name1'
                  : 'practice-content-model-menu-n-name2'
              "
            >
              模拟试题
            </div>
            <div
              :class="
                trigger == '模拟试题'
                  ? 'practice-content-model-menu-n-line1'
                  : 'practice-content-model-menu-n-line2'
              "
            ></div>
          </div>

          <div
            class="practice-content-model-menu-n"
            @click="toData('历年真题')"
          >
            <div
              :class="
                trigger == '历年真题'
                  ? 'practice-content-model-menu-n-name1'
                  : 'practice-content-model-menu-n-name2'
              "
            >
              历年真题
            </div>
            <div
              :class="
                trigger == '历年真题'
                  ? 'practice-content-model-menu-n-line1'
                  : 'practice-content-model-menu-n-line2'
              "
            ></div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="practice-content-content" v-loading="loading">
          <div
            class=""
            v-if="practiceList.length != null && practiceList.length != 0"
          >
            <div
              class="practice-content-content-n"
              v-for="(item, index) in practiceList"
              :key="index"
            >
              <div class="practice-content-content-n-left">
                <div class="headline">
                  <div class="content-n-left-id">{{ repair(index + 1) }}</div>
                  <div class="content-n-left-name">{{ item.name }}</div>
                </div>

                <div
                  :class="
                    item.ans_state.toString() == ''
                      ? 'content-n-left-bg'
                      : item.ans_state.toString() == '1'
                      ? 'content-n-left-bg1'
                      : 'content-n-left-bg2'
                  "
                >
                  {{
                    item.ans_state.toString() == ""
                      ? ""
                      : item.ans_state.toString() == "1"
                      ? "已提交"
                      : "未提交"
                  }}
                </div>
              </div>
              <div class="practice-content-content-n-rigth">
                <div class="suipian">
                  <div
                    class="content-n-rigth-sum"
                    v-if="item.ans_state.toString() == '' ? false : true"
                  >
                    <div class="content-n-rigth-sum-1">刷题次数：</div>
                    <div class="content-n-rigth-sum-2">
                      {{ item.ans_num }}
                    </div>
                  </div>
                  <div
                    class="content-n-rigth-proportion"
                    v-if="item.ans_state.toString() == '' ? false : true"
                  >
                    <div class="content-n-rigth-proportion-name">
                      <div class="content-n-rigth-proportion-name1">
                        上次正确率
                      </div>

                      <div
                        class="content-n-rigth-proportion-name2"
                        :style="{
                          color: classification(item.right_rate, 60)
                            ? '#FC6257'
                            : '#0D80FF',
                        }"
                      >
                        {{ item.right_rate }}%
                      </div>
                    </div>
                    <div class="content-n-rigth-proportion-proportion">
                      <div
                        class="content-n-rigth-proportion-proportion-n"
                        :style="{
                          left: proportion(item.right_rate),
                          background: classification(item.right_rate, 60)
                            ? '#FC6257'
                            : '#0D80FF',
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="look-analysis">
                  <div
                    v-if="item.ans_state.toString() == '' ? false : true"
                    class="content-n-rigth-proportion-analysis"
                    @click="seeAnalysis(item.ans_id, name, item.name)"
                  >
                    查看解析
                  </div>
                  <div
                          class="content-n-rigth-proportion-redo"
                          @click="toPath(item.id, item.ans_num, item.name, name, sum)"
                  >
                    {{ item.ans_state.toString() == "" ? "去完成" : "重做" }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="" v-else>
            <d404 :text="q404" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import d404 from "../data404";
import { getQuestionsId } from "../../../http/api/practice";
export default {
  created() {
    //在data中定义的数据还没被绑定,所以不要在这里赋值
  },
  mounted() {
    //定义数据已经被绑定  可以在这里进行数据ajax绑定
    this.getQuestionsId();
  },
  props: {
    //
  },

  data() {
    return {
      q404: "抱歉 , 您暂时没有内容~~",
      trigger: "章节练习", //章节  模拟   真题
      crs_id: "",
      practiceList1: [], //章节练习
      practiceList2: [], //模拟试题
      practiceList3: [], //历年真题

      practiceList: [], //显示
      name: "",
      sum: "",
      loading: true,
    };
  },
  components: {
    d404,
  },
  methods: {
    //分类
    classification(data, sum) {
      var classification = true;
      if (data > sum) {
        classification = false;
      } else {
        classification = true;
      }
      return classification;
    },

    getQuestionsId() {
      this.crs_id = this.$router.history.current.query.id;
      this.name = this.$router.history.current.query.name;
      this.sum = this.$router.history.current.query.sum;

      getQuestionsId(this.crs_id).then((res) => {
        if (res.code == 200) {
          this.practiceList1 = res.data_1;
          this.practiceList2 = res.data_3;
          this.practiceList3 = res.data_2;

          this.practiceList = this.practiceList1;
          this.loading = false;
        }
        // console.log(res);
      });
    },

    toPath(id, ans_num, name, title, sum) {
      // console.log(id);

      var path =
        "answer" +
        `?id=${id}&ans_num=${
          ans_num + 1
        }&name=${name}&title=${title}&sum=${sum}&idDefor=${this.crs_id}`;
      this.$router
        .push(path)
        .then(() => {})
        .catch((err) => err);
    },
    //查询解析
    seeAnalysis(ans_id, title, name) {
      var path =
        "seeAnalysis" +
        `?ans_id=${ans_id}&title=${title}&name=${name}&crs_id=${this.crs_id}&sum=${this.sum}`;
      this.$router
        .push(path)
        .then(() => {})
        .catch((err) => err);
    },
    proportion(val) {
      //val   0-100   30
      var data = 100 - val;
      var proportion = "-" + data + "%";
      return proportion;
    },
    toData(val) {
      this.trigger = val;
      if (val == "章节练习") {
        this.practiceList = this.practiceList1;
      } else if (val == "模拟试题") {
        this.practiceList = this.practiceList2;
      } else {
        this.practiceList = this.practiceList3;
      }
    },
    repair(val) {
      var repair = "";
      if (val.toString().length <= 1) {
        repair = "0" + val;
      } else {
        repair = val;
      }
      return repair;
    },
  },
};
</script>

<style lang="scss" scoped>
.practice-bg {
  width: 100%;
  height: 141px;
  background: url("../../../assets/img/exercise.png");
  background-size: cover;
}
.practice-bg-model {
  width: 67%;
  margin: auto;
  display: flex;
  padding-top: 5px;
  height: 155px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.practice-content-model {
  padding: 33px 0;
  width: 67%;
  margin: auto;
}
.practice-name {
  color: white;
  font-size: 18px;
}
.practice-sum {
  color: white;
  font-size: 14px;
  margin-top: 12px;
}
.practice-content-model-menu {
  display: flex;
}
.practice-content-model-menu-n {
  margin-right: 40px;
  cursor: pointer;
}
.practice-content-model-menu-n-name1 {
  font-size: 18px;
  padding-bottom: 12px;
  color: #00264d;
  font-weight: 600;
}
.practice-content-model-menu-n-name2 {
  font-size: 18px;
  color: #657489;
  padding-bottom: 12px;
}
.practice-content-model-menu-n-line1 {
  width: 100%;
  height: 2px;
  background: #0d80ff;

  transition: 0.5s;
}
.practice-content-model-menu-n-line2 {
  opacity: 0;
}
.practice-content-content {
  margin: 20px 0;
}
.practice-content-content-n {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background: white;
  padding: 25px 33px;
  flex-wrap: wrap;
  margin: 20px 0;
  align-items: center;
}
.practice-content-content-n:hover {
  .content-n-left-name {
    color: #2377e3;
  }
}

.practice-content-content-n-left {
  display: flex;
  // width: 520px;
}
.practice-content-content-n-rigth {
  display: flex;
  justify-content: space-between;
  /* width: 580px; */
}
.suipian {
  width: 430px;
  display: flex;
}
.headline {
  display: flex;
  line-height: 23px;
  align-items: center;
}
.content-n-left-id {
  color: #657489;
  font-size: 18px;
  font-weight: 600;
}
.content-n-left-name {
  margin: 0 16px;
  color: #00264d;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.content-n-left-bg {
}
.content-n-left-bg1 {
  text-align: center;
  color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
  background: #0d80ff;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}
.content-n-left-bg2 {
  text-align: center;
  color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
  background: #fc6257;
  font-size: 12px;
  cursor: pointer;
  line-height: 24px;
}
.content-n-rigth-sum {
  display: flex;
  align-items: center;
  width: 130px;
}
.content-n-rigth-sum-1 {
  color: #657489;
  font-size: 14px;
}
.content-n-rigth-sum-2 {
  color: #2377e3;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.content-n-rigth-proportion {
  margin: 0 27px;
  display: flex;
  align-items: center;
  margin: 0 35px;
  width: 230px;
}
.content-n-rigth-proportion-name {
  display: flex;
  align-items: center;
}
.content-n-rigth-proportion-name1 {
  color: #657489;
  font-size: 14px;
  margin-right: 8px;
}
.content-n-rigth-proportion-name2 {
  color: #fc6257;
  font-size: 11px;
  margin-left: 10px;
  font-weight: 600;
}
.content-n-rigth-proportion-proportion {
  width: 80px;
  height: 8px;
  border-radius: 5px;
  background: #eff0f5;
  overflow: hidden;
  margin-left: 10px;
}

.content-n-rigth-proportion-proportion-n {
  width: 80px;
  height: 8px;
  border-radius: 5px;
  background: #0080ff;
  position: relative;
  left: -100%;
  transition: 0.5s;
}
.look-analysis {
  display: flex;
  font-size: 16px;
  width: 184px;
}
.content-n-rigth-proportion-analysis {
  width: 88px;
  height: 30px;
  color: #0080ff;
  line-height: 30px;
  border: 1px #0d80ff solid;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
.content-n-rigth-proportion-redo {
  width: 84px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border-radius: 5px;
  border: 1px #0d80ff solid;
  cursor: pointer;
  background: #0080ff;
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .practice-content-model-menu-n {
    margin-right: 30px;
  }
  .practice-content-model {
    padding: 20px 20px;
    width: calc(100% - 40px);
    margin: auto;
  }
  .practice-content-content-n {
    display: block;
    border-radius: 6px;
    background: white;
    padding: 20px;
    flex-wrap: wrap;
    margin: 20px 0;
    align-items: center;
  }
  .headline{
    width: calc(100% - 50px);
  }
  .content-n-left-bg1,
  .content-n-left-bg2 {
    width: 50px;
    height: 28px;
    line-height: 28px;
  }
  .practice-content-content-n-left {
    display: flex;
    align-items: center;
    // width: 520px;
  }
  .practice-content-content-n-rigth {
    display: block;
    /* width: 580px; */
  }
  .suipian {
    width: 100%;
    display: block;
  }
  .content-n-rigth-sum {
    margin-top: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .content-n-rigth-proportion {
    margin: 0;
    margin-top: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .look-analysis {
    margin-top: 8px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .content-n-rigth-proportion-analysis {
    width: 80px;
    height: 32px;
    font-size: 14px;
    color: #0080ff;
    line-height: 32px;
    border: 1px #0d80ff solid;
    border-radius: 5px;
    text-align: center;
    margin-right: 10px;
  }
  .content-n-rigth-proportion-redo {
    width: 80px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: #ffffff;
    border-radius: 5px;
    border: 1px #0d80ff solid;
    cursor: pointer;
    font-size: 14px;
    background: #0080ff;
  }
}
</style>
