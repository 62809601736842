var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"practice"},[_c('div',{staticClass:"practice-bg"},[_c('div',{staticClass:"practice-bg-model"},[_c('div',{},[_c('div',{staticClass:"practice-name"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"practice-sum"},[_vm._v("共"+_vm._s(_vm.sum)+"题")])])])]),_c('div',{staticClass:"practice-content"},[_c('div',{staticClass:"practice-content-model"},[_c('div',{staticClass:"practice-content-model-menu"},[_c('div',{staticClass:"practice-content-model-menu-n",on:{"click":function($event){return _vm.toData('章节练习')}}},[_c('div',{class:_vm.trigger == '章节练习'
                ? 'practice-content-model-menu-n-name1'
                : 'practice-content-model-menu-n-name2'},[_vm._v(" 章节练习 ")]),_c('div',{class:_vm.trigger == '章节练习'
                ? 'practice-content-model-menu-n-line1'
                : 'practice-content-model-menu-n-line2'})]),_c('div',{staticClass:"practice-content-model-menu-n",on:{"click":function($event){return _vm.toData('模拟试题')}}},[_c('div',{class:_vm.trigger == '模拟试题'
                ? 'practice-content-model-menu-n-name1'
                : 'practice-content-model-menu-n-name2'},[_vm._v(" 模拟试题 ")]),_c('div',{class:_vm.trigger == '模拟试题'
                ? 'practice-content-model-menu-n-line1'
                : 'practice-content-model-menu-n-line2'})]),_c('div',{staticClass:"practice-content-model-menu-n",on:{"click":function($event){return _vm.toData('历年真题')}}},[_c('div',{class:_vm.trigger == '历年真题'
                ? 'practice-content-model-menu-n-name1'
                : 'practice-content-model-menu-n-name2'},[_vm._v(" 历年真题 ")]),_c('div',{class:_vm.trigger == '历年真题'
                ? 'practice-content-model-menu-n-line1'
                : 'practice-content-model-menu-n-line2'})])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"practice-content-content"},[(_vm.practiceList.length != null && _vm.practiceList.length != 0)?_c('div',{},_vm._l((_vm.practiceList),function(item,index){return _c('div',{key:index,staticClass:"practice-content-content-n"},[_c('div',{staticClass:"practice-content-content-n-left"},[_c('div',{staticClass:"headline"},[_c('div',{staticClass:"content-n-left-id"},[_vm._v(_vm._s(_vm.repair(index + 1)))]),_c('div',{staticClass:"content-n-left-name"},[_vm._v(_vm._s(item.name))])]),_c('div',{class:item.ans_state.toString() == ''
                    ? 'content-n-left-bg'
                    : item.ans_state.toString() == '1'
                    ? 'content-n-left-bg1'
                    : 'content-n-left-bg2'},[_vm._v(" "+_vm._s(item.ans_state.toString() == "" ? "" : item.ans_state.toString() == "1" ? "已提交" : "未提交")+" ")])]),_c('div',{staticClass:"practice-content-content-n-rigth"},[_c('div',{staticClass:"suipian"},[(item.ans_state.toString() == '' ? false : true)?_c('div',{staticClass:"content-n-rigth-sum"},[_c('div',{staticClass:"content-n-rigth-sum-1"},[_vm._v("刷题次数：")]),_c('div',{staticClass:"content-n-rigth-sum-2"},[_vm._v(" "+_vm._s(item.ans_num)+" ")])]):_vm._e(),(item.ans_state.toString() == '' ? false : true)?_c('div',{staticClass:"content-n-rigth-proportion"},[_c('div',{staticClass:"content-n-rigth-proportion-name"},[_c('div',{staticClass:"content-n-rigth-proportion-name1"},[_vm._v(" 上次正确率 ")]),_c('div',{staticClass:"content-n-rigth-proportion-name2",style:({
                        color: _vm.classification(item.right_rate, 60)
                          ? '#FC6257'
                          : '#0D80FF',
                      })},[_vm._v(" "+_vm._s(item.right_rate)+"% ")])]),_c('div',{staticClass:"content-n-rigth-proportion-proportion"},[_c('div',{staticClass:"content-n-rigth-proportion-proportion-n",style:({
                        left: _vm.proportion(item.right_rate),
                        background: _vm.classification(item.right_rate, 60)
                          ? '#FC6257'
                          : '#0D80FF',
                      })})])]):_vm._e()]),_c('div',{staticClass:"look-analysis"},[(item.ans_state.toString() == '' ? false : true)?_c('div',{staticClass:"content-n-rigth-proportion-analysis",on:{"click":function($event){return _vm.seeAnalysis(item.ans_id, _vm.name, item.name)}}},[_vm._v(" 查看解析 ")]):_vm._e(),_c('div',{staticClass:"content-n-rigth-proportion-redo",on:{"click":function($event){return _vm.toPath(item.id, item.ans_num, item.name, _vm.name, _vm.sum)}}},[_vm._v(" "+_vm._s(item.ans_state.toString() == "" ? "去完成" : "重做")+" ")])])])])}),0):_c('div',{},[_c('d404',{attrs:{"text":_vm.q404}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }